body {
    /* padding-top: 1.5rem; */
  }

  .l_f {
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-flex;
    vertical-align: middle;
}

.btn-lfy {
    color: var(--col_grey);
    background: 0 0;
    border: none;
    padding: 6px 8px;
    margin: 0 5px 0 0;
    display: inline-block;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.tost-bgn{
  background-color: aquamarine;
}