@import url(normalize.css);
/*LOGIN PAGE*/
/* #dcdef6;*/
body {
    /* background: url(../img/login-bg2.png) no-repeat center bottom; */
    font-family: 'Poppins', sans-serif;
    background-size: contain;
    background-color: #f5f9fc;
}
.centered {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-form{
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 8px;
    width: 420px;
    box-sizing: border-box;
    box-shadow: 0 0 15px #DDDDDD;
}

.login-logo{
    display: block;
    margin: 0 auto 32px;
}
.form-title{
    color: #666;
}
.input-group {
    margin-bottom: 24px;
}

.input-group label {
    margin-bottom: 8px;
    display: block;
    font-size: 13px;
}

.input-group input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #DDDDDD;
    font-size: 18px;
    padding: 16px;
    border-radius: 8px;
}

.input-group input:focus{
    outline: none;
    box-shadow: 0 0 4px #515bd3;
}
.form-check{
    margin-bottom: 24px;
}

.form-check input{
    margin-right: 8px;
    border:1px solid #DDDDDD;
}
.form-check label {
    font-weight: 300;
    font-size: 12px;
    cursor: pointer;
}
.reset-password {
    font-size: 12px;
    text-align: right;
}

.reset-password a {
    color: #181818;
    text-decoration: none;
    font-weight: 300;
}
.button-a-group input, button-a{
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid #515bd3;
    background-color: #515bd3;
    color: #FFFFFF;
    font-weight: 900;
    cursor: pointer;
}
button-a:disabled {
    background: rgb(81 91 211 / 50%);
    border: 1px solid rgb(81 91 211 / 60%);
    cursor: no-drop;
}
button-a i {
    margin-left: 8px;
}
.other-login {
    margin: 48px 0;
}
.alert{
    font-size: 14px;
    text-align: center;
}
.alert-box {
    text-align: center;
    font-size: 14px;
    padding: 8px;
    border-radius: 8px;
}
.success-box {
    background-color: #26b887;
    color: #ffffff;
}
.error-box{
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
}
.warning-box {
    background-color:yellow;
}
.text-centered{
    text-align: center;
}

.success-color{
    color:#26b887;
}

.error-color{
    color:#c54343;
}
.warning-color {
    color:yellow;
}
.information-area p {
    font-weight: 300;
    margin: 40px 0;
}
.text-centered-line {
    font-weight: 300;
    font-size: 12px;
    text-align: center;
}
.text-centered-line:after {
    content: "";
    height: 2px;
    background: #ddd;
    display: block;
    margin-top: -7px;
}

.text-centered-line span {
    background: #fff;
    padding: 0 16px;
}
.social-login a {
    display: block;
    margin-bottom: 16px;
    text-align: center;
}

.social-login .facebook {
    background: #507cc0;
    padding: 16px;
    border-radius: 8px;
    color: #ffffff;
    text-decoration: none;
}

.social-login a {
    display: block;
    margin-bottom: 16px;
    text-align: center;
}

.social-login .facebook i {
    margin-right: 10px;
}

.social-login .google {
    background: #df4930;
    padding: 16px;
    border-radius: 8px;
    color: #ffffff;
    text-decoration: none;
}

.social-login .google i {
    margin-right: 10px;
}
p.register-link {
    margin-top: 40px;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
}

p.register-link a {
    color: #000;
    text-decoration: none;
}
/*Responsive Rules*/
.column2 {
    width: 50%;
    float: left;
}

.home-page {
    height: 100vh;
}

.banner-image {
    background-image: url('login-bg2.png');
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1,0;
    height: 60%;
}


.inforide {
    box-shadow: 1px 2px 8px 0px #f1f1f1;
    background-color: white;
    border-radius: 8px;
    height: 125px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eceef3;
    border-radius: .75rem;
  }

  .card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    color: #16192c;
    background-color: transparent;
    border-bottom: 1px solid #eceef3;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    --x-table-bg: transparent;
    --x-table-accent-bg: transparent;
    --x-table-striped-color: #525F7F;
    --x-table-striped-bg: rgba(0, 0, 0, 0.05);
    --x-table-active-color: #525F7F;
    --x-table-active-bg: rgba(0, 0, 0, 0.1);
    --x-table-hover-color: #525F7F !important;
    --x-table-hover-bg: rgba(0, 0, 0, 0.02);
    width: 100%;
    margin-bottom: 1rem;
    color: #525f7f;
    vertical-align: middle;
    border-color: #e7eaf0;
    caption-side: bottom;
    border-collapse: collapse;
}

.table>thead {
    vertical-align: bottom;
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.rounded-circle {
    border-radius: 50% !important;
}

.table td {
    font-size: .8125rem;
    white-space: nowrap;
}

.table>:not(caption)>*>* {
    padding: 1rem 1rem;
    background-color: var(--x-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
}
  .card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}
  .card-graph {
    box-shadow: 1px 2px 8px 0px #f1f1f1;
    background-color: white;
    border-radius: 8px;
  }

  .avatar-sm {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}

.avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875rem;
    width: 2.875rem;
    border-radius: 0.375rem;
}

.text-heading {
    --x-text-opacity: 1;
    color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
}
.avatar-xs {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.675rem;
    border-radius: 0.25rem;
}

.avatar-site-xs {
    width: 1.1rem;
    height: 1.1rem;
    font-size: 0.675rem;
    border-radius: 0.25rem;
}

.font-semibold {
    font-weight: 500 !important;
}

.table td {
    font-size: .8125rem;
    white-space: nowrap;
}

.badge-dot {
    display: inline-flex;
    align-items: center;
    padding: 0;
    background: transparent;
    font-weight: 400;
    color: #525f7f !important;
}

.table .thead-light th {
    background-color: #f5f9fc;
    color: #8895b7;
}

.table thead th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: .675rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .025em;
    background-color: transparent;
    border-bottom-width: 1px;
    color: #525f7f;
    white-space: nowrap;
    vertical-align: middle;
}

.badge-lg {
    padding: 0.6rem 1rem;
    font-size: 1em;
}

.badge {
    display: inline-block;
    padding: 0.2rem 0.6rem;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
}

.badge-dot.badge-lg i {
    width: 0.625rem;
    height: 0.625rem;
}

.badge-dot i {
    display: inline-block;
    vertical-align: middle;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}


.table-hover>tbody>tr:hover {
    --x-table-accent-bg: var(--x-table-hover-bg);
    color: var(--x-table-hover-color);
}

.btn-square.btn-sm, .btn-group-sm>.btn-square.btn {
    width: 2.25rem;
    height: 2.25rem;
}

.btn-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.875rem;
    height: 2.875rem;
    padding: 0;
}

.btn-neutral {
    color: #16192c;
    background-color: #fff;
    border-color: #e7eaf0;
}
.btn-sm, .btn-group-sm>.btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
}
.btn2 {
    display: inline-block;
    font-weight: 500;
    line-height: 1.3;
    color: #525f7f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-square.btn-sm, .btn-group-sm>.btn-square.btn {
    width: 2.25rem;
    height: 2.25rem;
}

.btn-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.875rem;
    height: 2.875rem;
    padding: 0;
}

.btn-sm, .btn-group-sm>.btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
}

button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
.card-footer {
    padding: 1.25rem 1.5rem;
    color: #16192c;
    background-color: transparent;
    border-top: 1px solid #eceef3;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}
.py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}
.text-muted {
    --x-text-opacity: 1;
    color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
}

.text-sm-2 {
    font-size: 0.875rem !important;
}

.py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.bg-surface-secondary {
    background-color: #f5f9fc !important;
}
figcaption, figure, main {
    display: block;
    margin: 0;
}

.navbar [class^=container] {
    position: relative;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}
.h-screen {
    height: 100vh !important;
}

.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.border-bottom {
    border-bottom-width: 1px !important;
}
.navbar {
    --x-navbar-bg: #fff;
    --x-navbar-color: #16192c;
    --x-navbar-icon-color: #6b7b93;
    --x-navbar-hover-bg: #f5f9fc;
    --x-navbar-hover-color: #495392;
    --x-navbar-active-bg: #f5f9fc;
    --x-navbar-active-color: #495392;
    --x-navbar-nav-link-padding-y: 1rem;
    z-index: 100;
    position: relative;
}


.flex-column {
    flex-direction: column !important;
}
.d-flex {
    display: flex !important;
}
.bg-surface-secondary {
    background-color: #f5f9fc !important;
}
.collapse:not(.show) {
    display: none;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
.navbar-vertical .navbar-nav {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}


  
  .rideone img {
    width: 70%;
  }
  
  .ridetwo img {
    width: 70%;
  }
  
  .ridethree img {
    width: 70%;
  }
  
  .rideone {
    background-color: #caffd8;
    padding-top: 30px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 12px;
  }
  
  .ridetwo {
    background-color: #e1d5ff;
    padding-top: 30px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 12px;
  }
  
  .ridethree {
    background-color: #c2efff;
    padding-top: 35px;
    border-radius: 8px 0px 0px 8px;
    text-align: center;
    height: 125px;
    margin-left: 12px;
  }
  
  .fontsty {
    margin-right: -15px;
  }

  .fontsty-title {
    margin-left: 15px;
  }

  .fontsty-title h4 {
    color: #6E6E6E;
    font-size: 24px;
    margin-top: 18px;
    text-align: left;
    margin-right: 30px;
  }

  .fontsty-title h5 {
    color: #6E6E6E;
    font-size: 15px;
    margin-top: 15px;
    text-align: left;
    margin-right: 30px;
  }

  .bg-soft-success {
    background-color: #ccf5e7 !important;
}

.text-xs {
    font-size: 0.75rem !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.font-semibold {
    font-weight: 600 !important;
}

.text-sm {
    font-size: 1.1rem !important;
    color: #6E6E6E !important;
}
.me-2 {
    margin-right: 0.5rem !important;
}
.bg-soft-danger {
    background-color: #ffd6e0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #717171!important;
}

  .blue-progress-bar .progress-bar {
    background-color: #4c8df5 !important;
  }

  .pagination .page-link {
    border-top-style: none !important;
    border-bottom-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
  
  }

  .pagination {
    border-top-style: none !important;
    border-bottom-style: none !important;
    border-left-style: none !important;
    border-right-style: none !important;
    --bs-pagination-color: none !important;
    --bs-pagination-bg: none !important;
    --bs-pagination-hover-color: none !important;
    --bs-pagination-focus-box-shadow: none !important;
    --bs-pagination-focus-bg: none !important;
    --bs-pagination-focus-color: none !important;
    --bs-pagination-hover-bg: none !important;
    --bs-pagination-disabled-color: #ababab !important;
  
  }


  .fontsty-title h6 {
    color: #6E6E6E;
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    margin-right: 30px;
  }
  
  .fontsty h2{
    color: #6E6E6E;
    font-size: 35px;
    margin-top: 15px;
    text-align: right;
    margin-right: 30px;
  }
  
  .fontsty h4{
    color: #6E6E6E;
    font-size: 25px;
    margin-top: 20px;
    text-align: right;
    margin-right: 30px;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .form-signin input[type="password2"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .navbar-light .navbar-toggler {
    color: #6b7b93;
    background-color: transparent;
    border-color: transparent;
}






.navbar-light .navbar-toggler {
    color: #6b7b93;
    background-color: transparent;
    border-color: transparent;
}
button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer;
}


.ms-n2 {
    margin-left: -0.5rem!important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}







*, *::before, *::after {
    border: 0 solid #e7eaf0;
}



.navbar-vertical .navbar-nav {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
}
.navbar-vertical.navbar-expand-lg .navbar-nav {
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-direction: column;
}

.navbar-vertical .navbar-nav>.nav-item {
    margin-top: 2px;
}

.navbar-vertical .navbar-nav .nav-link {
    padding: 0.75rem 1.5rem;
    font-size: .875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link i, .navbar-vertical.navbar-light .navbar-nav .nav-link svg {
    color: #6b7b93;
}

.navbar-toggler {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    padding: 0.25rem 0.375rem;
    font-size: 1.25rem;
    line-height: 1;
    transition: box-shadow .15s ease-in-out;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: #16192c;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.navbar-nav .nav-link {
    font-size: .925rem;
    font-weight: 500;
    letter-spacing: 0;
    transition: all .15s linear;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #525f7f;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.navbar-vertical .navbar-nav .nav-link i, .navbar-vertical .navbar-nav .nav-link svg {
    min-width: 1.875rem;
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link:hover {
    background-color: #f5f9fc;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #495392;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important;
}

.ms-auto {
    margin-left: auto!important;
}
.align-items-center {
    align-items: center!important;
}
.d-inline-flex {
    display: inline-flex!important;
}

.pt-6 {
    padding-top: 1.5rem !important;
}

.border-bottom {
    border-bottom-width: 1px !important;
}
.bg-surface-primary {
    background-color: #fff !important;
}

.nav.overflow-x {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px;
}

.mt-4 {
    margin-top: 1rem !important;
}
.border-0 {
    border-width: 0 !important;
}
.nav-tabs {
    border-bottom: 1px solid #e7eaf0;
}
.nav-tabs .nav-item:first-child {
    margin-left: 0;
}

.nav.overflow-x .nav-item {
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
}
.nav-tabs .nav-item {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.mb-npx {
    margin-bottom: -1px !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.border-base {
    border-color: #e7eaf0 !important;
}
.d-inline-flex {
    display: inline-flex !important;
}


.pe-2 {
    padding-right: 0.5rem !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #5c60f5;
    background-color: transparent;
    border-color: transparent transparent #5c60f5;
}

.nav-tabs .nav-link {
    padding: 1.25rem 0;
    
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.bg-success {
    --bs-bg-opacity: 1;
    background-color: #00cc88 !important;
}
.form-switch .form-check-input:checked {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e);
    background-position: 100%;
}

.form-check-input:checked[type=checkbox] {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e);
}
.form-switch>.form-check-input {
    height: 1.5rem;
    margin-top: 0;
}
.form-switch .form-check-input {
    width: 2.875em;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
    background-position: 0;
    border-radius: 2.875em !important;
    margin-left: -3.375em;
    transition: background-position .15s ease-in-out;
}
.form-check-input:checked {
    background-color: #5c60f5;
    border-color: #5c60f5;
}
.form-check-input[type=checkbox] {
    border-radius: 0.25em;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.gap-6 {
    gap: 1.5rem!important;
}

.max-w-screen-md {
    max-width: 768px!important;
}
.vstack {
    flex-direction: column;
    flex: auto;
    align-self: stretch;
    display: flex;
}
.bg-white, .bg-white-hover:hover {
    --x-bg-opacity: 1;
    background-color: rgba(var(--x-white-rgb),var(--x-bg-opacity)) !important;
}



  @media (min-width: 992px){

    body {
        /* background: url(../img/login-bg2.png) no-repeat center bottom; */
        font-family: 'Poppins', sans-serif;
        background-size: contain;
        background-color: #f5f9fc;
    }
    .centered {
        display: grid;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    .navbar-light .navbar-toggler {
        color: #6b7b93;
        background-color: transparent;
        border-color: transparent;
    }
    
    .navbar-light .navbar-toggler {
        color: #16192c;
        border-color: transparent;
    }

    .navbar-vertical.navbar-expand-lg .navbar-brand {
        margin-right: 0;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .rounded-pill {
        border-radius: 50rem !important;
    }

    .pt-6 {
        padding-top: 1.5rem !important;
    }
    
    .border-bottom {
        border-bottom-width: 1px !important;
    }
    .bg-surface-primary {
        background-color: #fff !important;
    }

    .nav-tabs .nav-item:first-child {
        margin-left: 0;
    }

    .mx-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    
    .border-base {
        border-color: #e7eaf0 !important;
    }
    .d-inline-flex {
        display: inline-flex !important;
    }
   

    .pe-2 {
        padding-right: 0.5rem !important;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #5c60f5;
        background-color: transparent;
        border-color: transparent transparent #5c60f5;
    }
    
    .nav-tabs .nav-link {
        padding: 1.25rem 0;
        
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
    }
    .nav-tabs .nav-link {
        margin-bottom: -1px;
        background: none;
       
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .nav.overflow-x .nav-item {
        width: auto;
        max-width: 100%;
        flex: 0 0 auto;
    }
    .nav-tabs .nav-item {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    .mb-npx {
        margin-bottom: -1px !important;
    }

    .nav.overflow-x {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 1px;
    }
    
    .mt-4 {
        margin-top: 1rem !important;
    }
    .border-0 {
        border-width: 0 !important;
    }
    .nav-tabs {
        border-bottom: 1px solid #e7eaf0;
    }

    .text-primary {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-primary-rgb),var(--bs-text-opacity))!important;
    }
    
    .ms-auto {
        margin-left: auto!important;
    }
    .align-items-center {
        align-items: center!important;
    }
    .d-inline-flex {
        display: inline-flex!important;
    }

    .navbar-vertical .navbar-nav>.nav-item {
        margin-top: 2px;
    }

    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }

    .navbar-vertical .navbar-nav .nav-link {
        padding: 0.75rem 1.5rem;
        font-size: 1.1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-radius: 0;
    }

    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
        color: #495392;
    }

    .navbar-light .navbar-nav .nav-link {
        color: #16192c;
    }

    .navbar-vertical.navbar-light .navbar-nav .nav-link i, .navbar-vertical.navbar-light .navbar-nav .nav-link svg {
        color: #6b7b93;
    }

    .navbar-vertical .navbar-nav .nav-link i, .navbar-vertical .navbar-nav .nav-link svg {
        min-width: 1.875rem;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }

    .navbar-nav .nav-link {
        font-size: .925rem;
        font-weight: 500;
        letter-spacing: 0;
        transition: all .15s linear;
    }

    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        color: #525f7f;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }

    .navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link:hover {
        background-color: #f5f9fc;
    }
    
    
    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    .navbar-light .navbar-toggler {
        color: #16192c;
        border-color: transparent;
    }
    
    .navbar-light .navbar-toggler {
        color: #6b7b93;
        background-color: transparent;
        border-color: transparent;
    }
    
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    
    .navbar-light .navbar-toggler {
        color: #16192c;
        border-color: transparent;
    }
    .navbar-light .navbar-toggler {
        color: #6b7b93;
        background-color: transparent;
        border-color: transparent;
    }
    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    
    .navbar-toggler-icon {
        width: 1.25em;
        height: 1.25em;
    }
    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

   
    .navbar-brand {
        padding-top: var(--bs-navbar-brand-padding-y) !important;
        padding-bottom: var(--bs-navbar-brand-padding-y) !important;
        margin-right: var(--bs-navbar-brand-margin-end) !important;
        font-size: var(--bs-navbar-brand-font-size);
        color: var(--bs-navbar-brand-color);
        text-decoration: none;
        white-space: nowrap;
    }

    .py-lg-2 {
        padding-top: 0.5rem!important;
        padding-bottom: 0.5rem!important;
    }
    .mb-lg-5 {
        margin-bottom: 3rem!important;
    }
    .me-0 {
        margin-right: 0!important;
    }

    .login-form{
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        width: 420px;
        box-sizing: border-box;
        box-shadow: 0 0 15px #DDDDDD;
    }

    .d-lg-none {
        display: none !important;
    }
    
    .navbar-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    .login-logo{
        display: block;
        margin: 0 auto 32px;
    }
    .form-title{
        color: #666;
    }
    .input-group {
        margin-bottom: 24px;
    }
    
    .input-group label {
        margin-bottom: 8px;
        display: block;
        font-size: 13px;
    }
    
    .input-group input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #DDDDDD;
        font-size: 18px;
        padding: 16px;
        border-radius: 8px;
    }
    
    .input-group input:focus{
        outline: none;
        box-shadow: 0 0 4px #515bd3;
    }
    .form-check{
        margin-bottom: 24px;
    }
    
    .form-check input{
        margin-right: 8px;
        border:1px solid #DDDDDD;
    }
    .form-check label {
        font-weight: 300;
        font-size: 12px;
        cursor: pointer;
    }
    .reset-password {
        font-size: 12px;
        text-align: right;
    }
    
    .reset-password a {
        color: #181818;
        text-decoration: none;
        font-weight: 300;
    }
    .button-a-group input, button-a{
        display: block;
        width: 100%;
        padding: 16px;
        font-size: 18px;
        border-radius: 8px;
        border: 1px solid #515bd3;
        background-color: #515bd3;
        color: #FFFFFF;
        font-weight: 900;
        cursor: pointer;
    }
    button-a:disabled {
        background: rgb(81 91 211 / 50%);
        border: 1px solid rgb(81 91 211 / 60%);
        cursor: no-drop;
    }
    button-a i {
        margin-left: 8px;
    }
    .other-login {
        margin: 48px 0;
    }
    .alert{
        font-size: 14px;
        text-align: center;
    }
    .alert-box {
        text-align: center;
        font-size: 14px;
        padding: 8px;
        border-radius: 8px;
    }
    .success-box {
        background-color: #26b887;
        color: #ffffff;
    }
    .error-box{
        background-color: #f8d7da;
        border: 1px solid #f5c6cb;
        color: #721c24;
    }
    .warning-box {
        background-color:yellow;
    }
    .text-centered{
        text-align: center;
    }
    
    .success-color{
        color:#26b887;
    }
    
    .error-color{
        color:#c54343;
    }
    .warning-color {
        color:yellow;
    }
    .information-area p {
        font-weight: 300;
        margin: 40px 0;
    }
    .text-centered-line {
        font-weight: 300;
        font-size: 12px;
        text-align: center;
    }
    .text-centered-line:after {
        content: "";
        height: 2px;
        background: #ddd;
        display: block;
        margin-top: -7px;
    }
    
    .text-centered-line span {
        background: #fff;
        padding: 0 16px;
    }
    .social-login a {
        display: block;
        margin-bottom: 16px;
        text-align: center;
    }
    
    .social-login .facebook {
        background: #507cc0;
        padding: 16px;
        border-radius: 8px;
        color: #ffffff;
        text-decoration: none;
    }
    
    .social-login a {
        display: block;
        margin-bottom: 16px;
        text-align: center;
    }
    
    .social-login .facebook i {
        margin-right: 10px;
    }
    
    .social-login .google {
        background: #df4930;
        padding: 16px;
        border-radius: 8px;
        color: #ffffff;
        text-decoration: none;
    }
    
    .social-login .google i {
        margin-right: 10px;
    }
    p.register-link {
        margin-top: 40px;
        font-weight: 300;
        font-size: 12px;
        text-align: center;
    }
    
    p.register-link a {
        color: #000;
        text-decoration: none;
    }
    /*Responsive Rules*/
    .column2 {
        width: 50%;
        float: left;
    }
    
    .home-page {
        height: 100vh;
    }
    
    .banner-image {
        background-image: url('login-bg2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1,0;
        height: 60%;
    }
    
    
    .inforide {
        box-shadow: 1px 2px 8px 0px #f1f1f1;
        background-color: white;
        border-radius: 8px;
        height: 125px;
      }
    
      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #eceef3;
        border-radius: .75rem;
      }
    
      .card-header {
        padding: 1.25rem 1.5rem;
        margin-bottom: 0;
        color: #16192c;
        background-color: transparent;
        border-bottom: 1px solid #eceef3;
    }
    
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    
    .table {
        --x-table-bg: transparent;
        --x-table-accent-bg: transparent;
        --x-table-striped-color: #525F7F;
        --x-table-striped-bg: rgba(0, 0, 0, 0.05);
        --x-table-active-color: #525F7F;
        --x-table-active-bg: rgba(0, 0, 0, 0.1);
        --x-table-hover-color: #525F7F;
        --x-table-hover-bg: rgba(0, 0, 0, 0.02);
        width: 100%;
        margin-bottom: 1rem;
        color: #525f7f;
        vertical-align: middle;
        border-color: #e7eaf0;
        caption-side: bottom;
        border-collapse: collapse;
    }
    
    .table>thead {
        vertical-align: bottom;
    }
    
    thead, tbody, tfoot, tr, td, th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
    
    .rounded-circle {
        border-radius: 50% !important;
    }
    
    .table td {
        font-size: .8125rem;
        white-space: nowrap;
    }
    
    .table>:not(caption)>*>* {
        padding: 1rem 1rem;
        background-color: var(--x-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
    }
      .card-header:first-child {
        border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
    }
      .card-graph {
        box-shadow: 1px 2px 8px 0px #f1f1f1;
        background-color: white;
        border-radius: 8px;
      }
    
      .avatar-sm {
        width: 2.25rem;
        height: 2.25rem;
        font-size: 0.75rem;
        border-radius: 0.25rem;
    }
    
    .avatar {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        font-size: 1rem;
        font-weight: 600;
        height: 2.875rem;
        width: 2.875rem;
        border-radius: 0.375rem;
    }
    
    .text-heading {
        --x-text-opacity: 1;
        color: rgba(var(--x-heading-rgb), var(--x-text-opacity)) !important;
    }
    .avatar-xs {
        width: 1.75rem;
        height: 1.75rem;
        font-size: 0.675rem;
        border-radius: 0.25rem;
    }

    .avatar-site-xs {
        width: 1.1rem;
        height: 1.1rem;
        font-size: 0.675rem;
        border-radius: 0.25rem;
    }
    
    .font-semibold {
        font-weight: 500 !important;
    }
    
    .table td {
        font-size: .8125rem;
        white-space: nowrap;
    }
    
    .badge-dot {
        display: inline-flex;
        align-items: center;
        padding: 0;
        background: transparent;
        font-weight: 400;
        color: #525f7f !important;
    }
    
    .table .thead-light th {
        background-color: #f5f9fc;
        color: #8895b7;
    }
    
    .table thead th {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: .675rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .025em;
        background-color: transparent;
        border-bottom-width: 1px;
        color: #525f7f;
        white-space: nowrap;
        vertical-align: middle;
    }
    
    .badge-lg {
        padding: 0.6rem 1rem;
        font-size: 1em;
    }
    
    .badge {
        display: inline-block;
        padding: 0.2rem 0.6rem;
        font-size: 0.75em;
        font-weight: 600;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.375rem;
    }
    
    .badge-dot.badge-lg i {
        width: 0.625rem;
        height: 0.625rem;
    }
    
    .badge-dot i {
        display: inline-block;
        vertical-align: middle;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        margin-right: 0.5rem;
    }
    
    
    .table-hover>tbody>tr:hover {
        --x-table-accent-bg: var(--x-table-hover-bg);
        color: var(--x-table-hover-color);
    }
    
    .btn-square.btn-sm, .btn-group-sm>.btn-square.btn {
        width: 2.25rem;
        height: 2.25rem;
    }
    
    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    
    .btn-square {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2.875rem;
        height: 2.875rem;
        padding: 0;
    }
    
    .btn-neutral {
        color: #16192c;
        background-color: #fff;
        border-color: #e7eaf0;
    }
    .btn-sm, .btn-group-sm>.btn {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        border-radius: 0.375rem;
    }
    .btn2 {
        display: inline-block;
        font-weight: 500;
        line-height: 1.3;
        color: #525f7f;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.75rem 1.25rem;
        font-size: 1rem;
        border-radius: 0.375rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    button, [type=button], [type=reset], [type=submit] {
        -webkit-appearance: button;
    }
    
    .btn-square.btn-sm, .btn-group-sm>.btn-square.btn {
        width: 2.25rem;
        height: 2.25rem;
    }
    button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
        cursor: pointer;
    }
    .btn-square {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2.875rem;
        height: 2.875rem;
        padding: 0;
    }
   
    .btn-sm, .btn-group-sm>.btn {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        border-radius: 0.375rem;
    }
    
    button, [type=button], [type=reset], [type=submit] {
        -webkit-appearance: button;
    }
    button, select {
        text-transform: none;
    }
    .card-footer {
        padding: 1.25rem 1.5rem;
        color: #16192c;
        background-color: transparent;
        border-top: 1px solid #eceef3;
    }
    
    .card-footer:last-child {
        border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
    }
    .py-5 {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }
    
    .card-footer:last-child {
        border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
    }
    .text-muted {
        --x-text-opacity: 1;
        color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
    }
    
    .text-sm-2 {
        font-size: 0.875rem !important;
    }
    
    .py-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    
    .bg-surface-secondary {
        background-color: #f5f9fc !important;
    }
    figcaption, figure, main {
        display: block;
        margin: 0;
    }
    .bg-success {
        --bs-bg-opacity: 1;
        background-color: #00cc88 !important;
    }
    
    .navbar [class^=container] {
        position: relative;
    }
    .navbar>.container, .navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
        display: flex;
        flex-wrap: inherit;
        align-items: center !important;
        justify-content: space-between;
    }
    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        --x-gutter-x: 1.5rem;
    --x-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--x-gutter-x)*.5);
    padding-left: calc(var(--x-gutter-x)*.5);
    margin-left: auto;
    margin-right: auto;
    }
    .flex-grow-1 {
        flex-grow: 1 !important;
    }
    .h-screen {
        height: 100vh !important;
    }
    .bg-white {
        --x-bg-opacity: 1;
        background-color: rgba(var(--x-white-rgb), var(--x-bg-opacity)) !important;
    }
    .py-3 {
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }
    .px-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .border-bottom {
        border-bottom-width: 1px !important;
    }
    .navbar {
        --x-navbar-bg: #fff;
        --x-navbar-color: #16192c;
        --x-navbar-icon-color: #6b7b93;
        --x-navbar-hover-bg: #f5f9fc;
        --x-navbar-hover-color: #495392;
        --x-navbar-active-bg: #f5f9fc;
        --x-navbar-active-color: #495392;
        --x-navbar-nav-link-padding-y: 1rem;
        z-index: 100;
        position: relative;
    }
    .flex-column {
        flex-direction: column !important;
    }
    .d-flex {
        display: flex !important;
    }
    .bg-surface-secondary {
        background-color: #f5f9fc !important;
    }
    .collapse:not(.show) {
        display: none;
    }
    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }
    .navbar-vertical .navbar-nav {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    
    
      
      .rideone img {
        width: 70%;
      }
      
      .ridetwo img {
        width: 70%;
      }
      
      .ridethree img {
        width: 70%;
      }
      
      .rideone {
        background-color: #caffd8;
        padding-top: 30px;
        border-radius: 8px 0px 0px 8px;
        text-align: center;
        height: 125px;
        margin-left: 12px;
      }
      
      .ridetwo {
        background-color: #e1d5ff;
        padding-top: 30px;
        border-radius: 8px 0px 0px 8px;
        text-align: center;
        height: 125px;
        margin-left: 12px;
      }
      
      .ridethree {
        background-color: #c2efff;
        padding-top: 35px;
        border-radius: 8px 0px 0px 8px;
        text-align: center;
        height: 125px;
        margin-left: 12px;
      }
      
      .fontsty {
        margin-right: -15px;
      }
    
      .fontsty-title {
        margin-left: 15px;
      }
    
      .fontsty-title h4 {
        color: #6E6E6E;
        font-size: 24px;
        margin-top: 18px;
        text-align: left;
        margin-right: 30px;
      }
    
      .fontsty-title h5 {
        color: #6E6E6E;
        font-size: 15px;
        margin-top: 15px;
        text-align: left;
        margin-right: 30px;
      }
    
      .bg-soft-success {
        background-color: #ccf5e7 !important;
    }
    
    .text-xs {
        font-size: 0.75rem !important;
    }
    
    .text-nowrap {
        white-space: nowrap !important;
    }
    
    .font-semibold {
        font-weight: 600 !important;
    }
    
    .text-sm {
        font-size: 1.1rem !important;
        color: #6E6E6E !important;
    }
    .me-2 {
        margin-right: 0.5rem !important;
    }
    .bg-soft-danger {
        background-color: #ffd6e0 !important;
    }
    
    .mb-0 {
        margin-bottom: 0 !important;
    }
    
    .text-muted {
        --bs-text-opacity: 1;
        color: #717171!important;
    }
    
      .blue-progress-bar .progress-bar {
        background-color: #4c8df5 !important;
      }
    
      .pagination .page-link {
        border-top-style: none !important;
        border-bottom-style: none !important;
        border-left-style: none !important;
        border-right-style: none !important;
      
      }
    
      .pagination {
        border-top-style: none !important;
        border-bottom-style: none !important;
        border-left-style: none !important;
        border-right-style: none !important;
        --bs-pagination-color: none !important;
        --bs-pagination-bg: none !important;
        --bs-pagination-hover-color: none !important;
        --bs-pagination-focus-box-shadow: none !important;
        --bs-pagination-focus-bg: none !important;
        --bs-pagination-focus-color: none !important;
        --bs-pagination-hover-bg: none !important;
        --bs-pagination-disabled-color: #ababab !important;
      
      }
    .flex-lg-row {
        flex-direction: row !important;
    }
    .h-lg-full {
        height: 100% !important;
    }
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .border-end-lg {
        border-right-width: 1px !important;
    }
    .navbar-vertical.navbar-expand-lg.show {
        max-width: 270px !important;
        border-radius: 0;
    }
    .navbar-vertical.navbar-expand-lg {
        width: 100%;
        max-width: 270px !important;
        z-index: 1000;
        border-radius: 0;
        transition: all .2s ease-in-out;
        display: block;
        overflow-y: auto;
        box-shadow: 0 0 transparent!important;
    }
    .navbar-vertical.navbar-light {
        background-color: #fff !important;
        border-color: #e7eaf0 !important;
    }
    .h-lg-screen {
        height: 100vh !important;
    }
    .border-bottom-lg-0 {
        border-bottom-width: 0 !important;
    }
    .overflow-y-lg-auto {
        overflow-y: auto !important;
    }
    .navbar-vertical.navbar-expand-lg>[class*=container] {
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch !important;
        opacity: 1;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-vertical.navbar-expand-lg .navbar-nav {
        margin-left: 0 !important;
        margin-right: 0 !important;
        flex-direction: column;
    }
    .navbar-vertical.navbar-expand-lg .navbar-brand {
        margin-right: 0;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
}
